<template>
  <div class="debug-parent">
    <!-- DEBUG PARENT INNER -->
    <div
      class="debug-parent-inner has-scrollbars"
      :style="{
        pointerEvents: isAdmin ? 'visible' : 'none',
      }"
    >
      <!-- WINDOW CASE -->
      <div
        class="d-flex is-interactable"
        style="width: 800px"
      >
        <!-- WINDOW 1 -->
        <DebugWindow
          class="mb-3 mr-2 w-50"
          :init-mode="0"
          @close-window="onClose"
        />

        <!-- WINDOW 2 -->
        <DebugWindow
          class="w-50"
          :init-mode="2"
          @close-window="onClose"
        />
      </div>

      <!-- CONSOLE -->
      <DebugWindowConsole
        class="mb-3 is-interactable"
        style="width: 800px"
      />

      <!-- CHAT USERS (DEPRECATED) -->
      <div
        v-if="chatbotActive"
        class="chat-users is-interactable"
      >
        <!-- BUTTONS -->
        <div class="chat-users-btns">
          <!-- CLEAR USERS -->
          <button
            class="btn btn-clear"
            title="Clear Users"
            @click="onClearUsers"
          >
            <i class="fa fa-ban mr-1"></i>
          </button>

          <!-- SAVE USERS -->
          <button
            class="btn btn-save"
            title="Save Users"
            @click="onSaveUsers"
          >
            <i class="fa fa-cloud-upload mr-1"></i>
          </button>
        </div>

        <h2>Chat Users</h2>
        <div
          v-if="chatViewers"
          v-for="user in chatViewers"
          :key="user.username"
          class="chat-user"
          :class="{
            'is-active': user.active,
            'is-member': !user.guest,
            'is-idle': user.idle,
          }"
        >
          <span class="chat-user-username">
            <i
              v-if="user.idle"
              class="fa fa-clock-o mr-1"
            ></i>
            <i
              v-if="!user.guest"
              class="fa fa-database mr-1"
            ></i>
            {{ user.username }}
          </span>

          <span class="mr-2">
            <i class="fa fa-money mr-1"></i>
            {{ getPointsByUsername(user.username) }}
          </span>

          <span class="mr-1">
            <i class="fa fa-envelope mr-1"></i>
            {{ user.statsLocal.totalMessages }}
          </span>

          <span class="text-white ml-2">
            <i class="fa fa-bolt"></i>
            {{ user.statsLocal.totalCommands }}
          </span>
        </div>
      </div>

      <!-- QUICK TASKS -->
      <AdminPanelQuickTasks
        style="
          width: 792px;
          height: 253px;
          position: absolute;
          bottom: 120px;
          left: 850px;
          background-color: rgba(0, 0, 0, 0.4);
        "
      />

      <!-- QUICK UTILITIES -->
      <div
        class="quick-utilities mb-4 is-interactable"
        style="width: 800px"
      >
        <button
          class="mr-2 btn btn-success"
          @click="onTVOn"
        >
          <i class="fa fa-television mr-1"></i> TV On
        </button>

        <button
          class="mr-4 btn btn-light"
          @click="onTVLogo"
        >
          <i class="fa fa-bolt mr-1"></i> TV Logo
        </button>

        <button
          class="mr-2 btn btn-info"
          @click="onTask('a2ad4486-bf0c-4619-9cf7-f536f962e07d')"
        >
          Chat
        </button>

        <button
          class="mr-2 btn btn-info"
          @click="onTask('62829a37-2c69-4a5c-9212-25f91a61777f')"
        >
          Creep
        </button>

        <button
          class="mr-4 btn btn-info"
          @click="onTask('f021942b-409c-4541-8eed-9c2eab7da51d')"
        >
          Games
        </button>

        <!-- SKIP POSTER -->
        <button
          class="mr-2 btn btn-danger"
          @click="onTask('7113de8a-0360-4368-8eac-021174330922')"
        >
          <i class="fa fa-step-forward mr-1"></i> MP
        </button>

        <!-- PAUSE POSTER -->
        <button
          class="mr-2 btn btn-success"
          :class="{ 'is-active': mediaPosterPaused }"
          @click="onTask('c388392f-1931-4481-8c7e-99a0755b642a')"
        >
          <i class="fa fa-pause mr-1"></i> MP
        </button>

        <!-- HIDE POSTER -->
        <button
          class="mr-2 btn btn-light"
          :class="{ 'is-active': mediaPosterHidden }"
          @click="onTask('156ccfdd-d055-4b7b-b695-c917641ddfde')"
        >
          <i class="fa fa-eye-slash mr-1"></i> MP
        </button>

        <!-- CLEAR ALL POSTER -->
        <button
          class="btn btn-danger"
          @click="onTask('fe598845-ffe2-49a4-a3d6-44a0d0ffd646')"
        >
          <i class="fa fa-ban mr-1"></i> ALL
        </button>

        <button
          class="btn btn-logout btn-dark mr-3"
          @click="logout()"
        >
          Logout
        </button>

        <button
          class="mr-2 btn btn-success"
          @click="onSoundWeGotOne"
        >
          <i class="fa fa-music mr-1"></i> We Got One
        </button>

        <button
          class="mr-4 btn btn-success"
          @click="onTask('ae6e7c29-9fd6-41a8-8425-c0d483348d3e')"
        >
          <i class="fa fa-music mr-1"></i> $$$$$$$$
        </button>

        <button
          class="mr-2 btn btn-warning"
          @click="onPhone"
        >
          <i class="fa fa-phone mr-1"></i> PHONE
        </button>

        <button
          class="mr-2 btn btn-primary"
          @click="onTask('99c002f6-3f43-46a1-8b22-bf967a57120a')"
        >
          <i class="fa fa-phone mr-1"></i> MOTO
        </button>

        <button
          class="mr-4 btn btn-light"
          @click="onTask('d883090c-674b-4e63-9d69-ae83db93ae3a')"
        >
          <i class="fa fa-phone"></i> NOKIA
        </button>

        <br />

        <button
          class="mr-2 btn btn-light"
          @click="onTask('f8718bcf-3fa9-49fa-a0dc-da44d3ab87a1')"
        >
          <i class="fa fa-users"></i> APPLAUSE
        </button>

        <button
          class="mr-4 btn btn-light"
          @click="onTask('6b9b4120-8edd-485f-9dad-bb1324169664')"
        >
          <i class="fa fa-users"></i> CHEER
        </button>

        <button
          class="mr-2 btn btn-info"
          @click="onTask('1db30a61-bea4-4613-a574-c52ba009bb64', 'loficardboardcowboy')"
        >
          <i class="fa fa-twitch"></i> 24/7
        </button>

        <button
          class="mr-2 btn btn-info"
          @click="postToPage()"
        >
          POST
        </button>

        <small class="mx-2">
          <a
            class="mr-3"
            target="_blank"
            href="https://www.twitch.tv/themitchinghour/dashboard/live"
            >Dash</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/admin"
            >Admin</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/all"
            >All</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/background"
            >BG</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/foreground"
            >FG</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/simulation"
            >Sim</a
          >
          <a
            class="mr-3"
            target="_self"
            href="/"
            >User</a
          >

          <a
            href="/popout/chat"
            onclick="window.open(this.href,'targetWindow',
					                                   `toolbar=no,
					                                    location=no,
					                                    status=no,
					                                    menubar=no,
					                                    scrollbars=yes,
					                                    resizable=yes,
					                                    width=400,
					                                    height=800`);
					 return false;"
            >Chat</a
          >
        </small>

        <button
          class="ml-2 btn btn-info"
          title="Clear local storage - also may need to reboot browser if bugs."
          @click="onClearLocalStorage()"
        >
          <i class="fa fa-ban"></i>
        </button>

        <button
          class="ml-2 btn btn-info"
          @click="onTestDelayStart()"
        >
          Test delayStart
        </button>

        <button
          class="ml-2 btn btn-info"
          @click="onTask('99219979-ff89-48a8-b026-71157c858fe4')"
        >
          <i class="fa fa-eyedropper"></i> Transparent
        </button>

        <button
          class="ml-2 btn btn-warning"
          @click="onTask('c93cb734-7a6e-447b-809f-52a3c937ee12')"
        >
          <i class="fa fa-circle-o mr-1"></i> Lobby Mask
        </button>

        <!-- May be redundant now -->
        <button
          class="ml-4 btn btn-success"
          @click="onCopyPlaylist"
        >
          <i class="fa fa-download mr-1"></i> Copy Rqs
        </button>

        <button
          class="ml-2 btn btn-danger"
          @click="onTask('24910df5-4fa2-4de3-b22d-c6ed7d9b12dc')"
        >
          <i class="fa fa-warning"></i> Censor
        </button>
      </div>

      <!-- FOLLOW TITLES -->
      <div
        v-if="debugActive"
        class="follow-titles has-scrollbars is-interactable"
      >
        <ol>
          <li
            v-for="(item, index) in followTitles"
            :key="'follow-title-' + index"
          >
            {{ item }}
          </li>
        </ol>
      </div>

      <!-- CANCELED TASKS -->
      <div
        class="is-interactable canceled-tasks"
        style="
          overflow: hidden;
          background-color: #191919;
          width: 800px;
          padding: 15px 20px 10px 20px;
        "
      >
        <h4 class="text-light">
          CANCELED TASKS <small class="text-muted ml-2"> {{ canceledTasksCount }} </small
          ><button
            class="ml-4 btn btn-tiny btn-danger"
            @click="onTask('63c98cd1-d579-436b-b4b7-73f14f72dbd7')"
          >
            <i class="fa fa-ban text-error"></i>
          </button>
        </h4>

        <ul class="list-unstyled">
          <li
            v-for="(item, index) in allTasks"
            v-if="item.canceled || item.sleeping"
            :key="'cannedd-' + index + item.uuid"
            class="text-white"
          >
            <i class="fa fa-ban mr-1 text-danger"></i> {{ item.group }}:
            {{ item.data && item.data.value }}, "{{ item.data && item.data.arg }}" -
            <i class="text-danger">{{
              (item.sleeping && "sleeping") || (item.canceled && "awaiting deletion")
            }}</i>
            <small>{{ item.id }}</small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import { firebase, firebaseApp } from "@/config/firebase.js";
  import DebugWindow from "./DebugWindow.vue";
  import DebugWindowConsole from "./DebugWindowConsole.vue";
  import AdminPanelQuickTasks from "../Admin/AdminPanels/Default/AdminPanelQuickTasks/AdminPanelQuickTasks.vue";

  export default {
    components: {
      DebugWindow,
      DebugWindowConsole,
      AdminPanelQuickTasks,
    },
    data() {
      return {
        wideView: null,
      };
    },
    computed: {
      ...mapGetters({
        allTasks: "tasks/getAllTasks",
        chatViewers: "chatbot/getUsers",
        getActiveTask: "tasks/getActiveTask",
        getTaskFromLibraryByUUID: "tasks/getTaskFromLibraryByUUID",
        getPlaylist: "youtube/getPlaylist",
        formatPoints: "chatbot/getPointsFormatted",
        followTitles: "global/getFollowTitles",
      }),
      chatbotActive() {
        return !!this.getActiveTask("chatbot", "Run");
      },
      debugActive() {
        return !!this.getActiveTask("debug", "Run");
      },
      canceledTasksCount() {
        return this.allTasks.filter((item) => item.canceled).length;
      },
      clearAllCanceledTasks() {
        return !!this.getActiveTask("debug", "Clear Canceled Tasks");
      },

      mediaPosterHidden() {
        return !!this.getActiveTask("mediaPoster", "Hide");
      },
      mediaPosterPaused() {
        return !!this.getActiveTask("mediaPoster", "Pause");
      },
      isAdmin() {
        return window.location.pathname.includes("/admin");
      },
    },
    watch: {
      clearAllCanceledTasks(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.$store.dispatch("tasks/removeAllCanceledTasks");
        }
      },
    },
    methods: {
      formatTime(target) {
        const now = moment();
        const expiry = moment(target);
        // return moment(now.to(expiry)).format('hh:mm:ss');
        return expiry.fromNow();
        // return moment(target).format('hh:mm:ss');
        // return target;
      },

      formatExpiry(target) {
        return moment(target).format("h:mm:ssa");
      },

      // https://www.sipios.com/blog-tech/webhook-example-how-to-build-a-chatbot-from-scratch
      // https://stackoverflow.com/questions/29775797/fetch-post-json-data
      // https://stackoverflow.com/questions/10005939/how-do-i-consume-the-json-post-data-in-an-express-application
      postToPage(uri) {
        // const url = uri || 'http://10.0.1.29:3000/api/v1/twitch/follows?id=403955140';
        // const url = 'http://localhost:3000/api/v1/twitch/get-users?q=eethan';
        const url = "http://localhost:3000/api/v1/twitch/get-subscriptions";

        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => console.log("POSTED TO, RES BACK: ✅✅ ", data));
      },

      getPointsByUsername(username) {
        return this.formatPoints(this.$store.getters["chatbot/getPointsByUsername"](username));
      },
      // that alternate way to display currency:
      // formatCurrency(points) {
      // 	// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
      // 	// Create our number formatter.
      // 	// var formatter = new Intl.NumberFormat('en-US', {
      // 	//   style: 'currency',
      // 	//   currency: 'USD',
      // 	// });

      // 	// return formatter.format(points).slice(1); /* 2500: $2,500.00 */
      // 	return String(points).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      // },
      logout() {
        // move this to vuex
        this.$store.dispatch("user/logout");
      },
      onClose() {
        this.$store.dispatch("tasks/runTasks", [{ uuid: "fff1cc2c-7f98-46e6-b88e-a53697b7d518" }]);
      },
      onClearLocalStorage() {
        localStorage.removeItem("TheMitchingHour");
        // this.$store.state.followTitles = [];
        location.reload();
      },
      onClear() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          {
            uuid: "4a911af7-822c-4b22-8419-274b826f93c3",
            data: {
              arg: "*",
            },
          },
        ]);
      },
      onClearUsers() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "7cc39a8d-bc51-4f8a-874f-20a0ff5236c8" },
        ]);
      },
      onCopyPlaylist() {
        let copyText = "!rq ";

        this.getPlaylist.forEach((item) => {
          copyText += item.playerVars.id + ",";
        });

        function copyNow(text) {
          var input = document.createElement("textarea");
          input.innerHTML = text;
          document.body.appendChild(input);
          input.select();
          var result = document.execCommand("copy");
          document.body.removeChild(input);
          return result;
        }

        console.log(copyText);

        copyNow(copyText);
      },
      onLock() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "5c5d94d1-8597-4b0c-83aa-faa58044bc81" },
        ]); // canceled: false -- for testing
      },
      onPhone() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "cfe4bfb5-7406-4c43-8ce2-52992f8844ca" },
        ]);
      },
      onShuffle() {
        // toggles
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "e2736e43-e470-43f9-b135-d7adb13773a3" },
        ]); //canceled: false
      },
      onSaveUsers() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "a977190c-dd62-4480-a0c8-79f9a641b53b" },
        ]); // writeUsers
      },
      onSoundWeGotOne() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "d427b71c-3097-4d96-9a05-bc6e1d961ca8" },
        ]);
      },
      onTestDelayStart() {
        // Throw static then logo.
        this.$store.dispatch("tasks/runTasks", [
          // { uuid: 'c406a414-ba01-44f6-804a-2f7f3a8ff565', delayStart: 4000 } //, delayStart: 500
          // { uuid: 'ae6e7c29-9fd6-41a8-8425-c0d483348d3e', delayStart: 2000 }
          { uuid: "c406a414-ba01-44f6-804a-2f7f3a8ff565", delayStart: 8000 },
        ]);
      },
      onTVOn() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "3f828ebf-b109-4074-bcc0-23086a3872cd" },
        ]);
      },
      onTVLogo() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "cdd86256-a166-4b33-a66a-70f01bdcfcfa" },
        ]);
      },
      onRemoveAllCanceledTasks() {
        this.$store.dispatch("tasks/removeAllCanceledTasks");
      },
      onRequestPlaylistTest() {
        this.$store.dispatch("tasks/runTasksRemotely", [
          //https://youtu.be/0hW-B9aGEqM
          {
            uuid: "31548834-e770-47a2-ae0d-81bae3012f5f",
            data: {
              // pshow gone
              arg: "iDO9J_3OVJ0,BZP1rYjoBgI,krlSC1Qp6_c,9SnjPZaFd7Y,kIDWgqDBNXA,zBY0HaX4YCo,i6sTatKAXt0,f9sg_HhSa-Y",
              // arg: 'iDO9J_3OVJ0,krlSC1Qp6_c,9SnjPZaFd7Y'
            },
          },
        ]);
      },
      onTask(uuid, arg) {
        const task = { uuid: uuid, data: { arg: null } };
        if (arg) task.data.arg = arg;
        this.$store.dispatch("tasks/runTasksRemotely", [task]);
      },
      // onVol15() {
      // 	this.$store.dispatch('tasks/runTasksRemotely', [{ uuid: '85d7e282-f9a6-442e-9fe7-5d935499ea5e', canceled: false, data: { arg: 15 } }]);
      // },
      // onVol30() {
      // 	this.$store.dispatch('tasks/runTasksRemotely', [{ uuid: '85d7e282-f9a6-442e-9fe7-5d935499ea5e', canceled: false, data: { arg: 30 } }]);
      // },
      // onVol50() {
      // 	this.$store.dispatch('tasks/runTasksRemotely', [{ uuid: '85d7e282-f9a6-442e-9fe7-5d935499ea5e', canceled: false, data: { arg: 50 } }]);
      // },
      // onVol80() {
      // 	this.$store.dispatch('tasks/runTasksRemotely', [{ uuid: '85d7e282-f9a6-442e-9fe7-5d935499ea5e', canceled: false, data: { arg: 80 } }]);
      // }
    },
  };
</script>

<style lang="scss" scoped>
  .debug-parent {
    position: absolute;
    top: rem-calc(35);
    left: 0;
    right: 0;
    // right: rem-calc(400);
    height: calc(100% - #{rem-calc(35)});
    // border: 2px dashed aqua;
    // background: aqua;
    overflow-y: auto;
    overflow-x: hidden;
    // z-index: 9999999999;
    z-index: 10;
    pointer-events: none;
  }

  .debug-parent-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: rem-calc(20);
    // border: 1px dashed yellow;
    // pointer-events: auto;
    // -webkit-overflow-scrolling: touch; // not on chrome
    z-index: 1;
  }

  .is-interactable {
    pointer-events: auto;
  }

  //--------------------------------------------------------
  // DEBUG VIDEO CAPTURE
  //--------------------------------------------------------

  .debug-video-capture {
  }

  //--------------------------------------------------------
  // CONSOLE
  //--------------------------------------------------------

  .console textarea {
    width: 100%;
    height: 100%;
    padding: rem-calc(15 15);
    background: rgba(black, 0.8);
    border: 0;
    font-family: "Andale Mono";
    font-size: rem-calc(16);
    line-height: 1;
    font-weight: 700;
    color: #28fe14;
    outline: none;
    box-shadow: none;
  }

  .console button {
    display: none;
  }

  .quick-utilities {
    background: rgba(black, 0.4);
    border-radius: 10px;
    padding: rem-calc(20 20 10 20);

    button {
      font-weight: 600;
      margin-bottom: rem-calc(20);
    }

    .is-active {
      opacity: 0.3;
      transform: scale(0.9);
    }
  }

  //--------------------------------------------------------
  // CHAT USERS
  //--------------------------------------------------------

  .chat-users {
    position: absolute;
    top: rem-calc(70);
    left: rem-calc(850);
    width: rem-calc(440);
    height: rem-calc(766); //rem-calc(650);
    // padding: rem-calc(20);
    background: rgba(black, 0.4);
    font-size: rem-calc(17);
    color: white;
    overflow-y: auto;
    z-index: 999999;

    .chat-user:nth-child(even) {
      background: rgba(white, 0.05);
    }
  }

  h2 {
    margin: rem-calc(20);
  }

  .chat-user {
    display: flex;
    align-items: center;

    opacity: 0.4;
    white-space: nowrap;
    padding: rem-calc(4 20);
    user-select: none;

    &:hover {
      background: rgba(black, 0.1) !important;
    }

    .fa {
      line-height: 0;
    }

    .chat-user-username {
      display: inline-block;
      width: rem-calc(220);
      margin-right: rem-calc(10);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // line-height: 1.2;
    }

    &.is-active {
      color: #a5ff0c;
      opacity: 1;
    }

    &.is-member.is-active:not(.is-idle) {
      color: #54d1fb;
    }

    &.is-idle {
      color: #e97f0c;
    }
  }

  //--------------------------------------------------------
  // CHAT USERS BUTTONS
  //--------------------------------------------------------

  .chat-users-btns {
    position: absolute;
    top: 0;
    right: 0;
    color: white;

    button {
      color: grey;
      transition: none;

      &:hover {
        color: white;
      }
    }
  }

  .follow-titles {
    position: absolute;
    bottom: 0;
    right: 0;
    width: rem-calc(190);
    height: rem-calc(300);
    background: rgba(black, 0.2);
    color: black;
    overflow-y: scroll;
    white-space: nowrap;
  }

  .btn-100 {
    position: absolute;
    background: #fad223;
    top: rem-calc(19);
    left: rem-calc(600);
    z-index: 100;
  }
</style>
